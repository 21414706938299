import { BodyContent } from '@/api/types'
import { parse } from 'node-html-parser'

export const replaceImg = (body: BodyContent[]): BodyContent[] => {
  return body.map((b) => {
    const content = parse(b.richContent)
    content.getElementsByTagName('img').forEach((i, idx) => {
      const { src } = i.attrs
      const size = 'w=1600&h=900';
      const dpr = 'dpr=2';
      const webp = `${src}?fm=webp&${size}&${dpr}`;
      const avif = `${src}?fm=avif&${size}&${dpr}`;
      const newImg = `<div className="w-full aspect-video"><picture><source srcSet=${avif} type="image/avif" /><source srcSet=${webp} type="image/webp" /><img src=${src} width="100%" height="100%" loading="lazy" alt=image-${idx + 1} style="object-fit: cover; max-width: 100%;"/></picture></div>`
      i.replaceWith(parse(newImg))
    })

    return { ...b, richContent: content.toString()}
  })
}
